import script from "./ViewCard.vue?vue&type=script&setup=true&lang=js"
export * from "./ViewCard.vue?vue&type=script&setup=true&lang=js"

import "./ViewCard.vue?vue&type=style&index=0&id=57b4a526&lang=css"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QBtn,QDialog,QCard,QInput,QRadio,QPagination,QImg,QCardActions,QChip,QIcon,QAvatar,QCircularProgress});qInstall(script, 'directives', {ClosePopup,Ripple});
