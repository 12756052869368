<template>

	<!------------------- Link ------------------------>
	<div class="row">
		<div>
			<q-breadcrumbs :active-color="state.color">
				<q-breadcrumbs-el label="HOME" icon="home" href="/" />
				<q-breadcrumbs-el v-if="state.device!='home'" :label="state.device.toUpperCase()" :to="'/'+state.device" />
				<q-breadcrumbs-el :label="pageName" />
			</q-breadcrumbs>
		</div>
	</div>
    
	<!------------------- Calendar ------------------------>
	<div v-if="mode=='calendar'">
		<div class="q-pa-md row q-gutter-md">
			<q-select v-model="year" :options="years" label="Year" :color="state.color" class="col-xs-3 col-sm-3 col-md-2" dense />
			<q-select v-model="month" :options="months" label="Month" :color="state.color" class="col-xs-5 col-sm-3 col-md-2" dense />
			<q-btn round icon="search" :color="state.color" @click="refreshCd()"/>
		</div>
		<div class="q-pa-xs row">
			<q-chip size="md" outline color="grey-7" square>
				<q-avatar icon="calendar_month" :color="state.color" text-color="white" />
				{{chipTxt}}
			</q-chip>
		</div>
	</div>


	<!------------------- Search ------------------------>
	<div v-else-if="mode=='search'">
		<div class="q-pt-md q-gutter-md row items-start">
			<q-input v-model="search" label="Search" @keydown.enter="searchData()" 
				:color="state.color" clearable dense class="col-xs-9 col-sm-5" />
			<q-btn round :color="state.color" icon="search" @click="searchData()"/>
		</div>
		<div v-if="state.device!='home'" class="row items-start">
			<q-select
				:color="state.color"
				class="q-px-xs col-xs-4 col-sm-2 col-md-2"
				v-model="filter"
				:options="filters"
				option-value="value"
				option-label="label"
				label="Filter" 
				emit-value
				map-options
				multiple
				dense
				clearable
				behavior="menu"
			>
				<template v-slot:selected>
					<div class="ellipsis" v-if="filter && filter.length>0">{{filter.length}} selected</div>
				</template>
				<template v-slot:option="{ itemProps, opt, selected, toggleOption }">
					<q-item v-bind="itemProps">
						<q-item-section>
							<q-item-label>{{opt.label}}</q-item-label>
						</q-item-section>
						<q-item-section side>
							<q-toggle :model-value="selected" @update:model-value="toggleOption(opt)" />
						</q-item-section>
					</q-item>
				</template>
			</q-select>
			<q-select
				:color="state.color"
				class="q-px-xs col-xs-4 col-sm-2 col-md-2"
				v-model="price"
				:options="prices"
				option-value="value"
				option-label="label"
				label="Price" 
				emit-value
				map-options
				dense
				clearable
				behavior="menu"
			>
				<template v-slot:selected-item="scope">
					<div class="ellipsis">{{scope.opt.label}}</div>
				</template>
			</q-select>
		</div>
	</div>

	<div v-if="mode != 'search' || (formData.order && !chkInput)" class="q-pa-md row q-col-gutter-md">
		<ViewCard :formData="formData" :key="refresh" />
	</div>
	<div v-if="chkInput && mode=='search'" >
		<div class="text-h1 text-weight-medium" style="color:#D5D8DC ">(-_-)</div>
		<div class="text-h5 q-pa-md" style="color:#D5D8DC ">Please input some text or filter</div>
	</div>

</template>

<script setup>

	/*------------------------------- Import -----------------------------*/
	import { ref } from 'vue';
	import ViewCard from '../components/ViewCard.vue';
	import { useState } from '../stores/webStore';
	import { useRoute } from 'vue-router';
    const route = useRoute();
	const state = useState();
	state.setRoute(route.name);
	/*------------------------------- Data -----------------------------*/
	let loc = state.loc;
	let formData = ref({});
	let mode = ref('');
	let refresh = ref(0);
	let year = ref('');
	let years = ref([]);
	let month = ref('');
	let months = [
		{ label: 'January', value: '01' },
		{ label: 'February', value: '02' },
		{ label: 'March', value: '03' },
		{ label: 'April', value: '04' },
		{ label: 'May', value: '05' },
		{ label: 'June', value: '06' },
		{ label: 'July', value: '07' },
		{ label: 'August', value: '08' },
		{ label: 'September', value: '09' },
		{ label: 'October', value: '10' },
		{ label: 'November', value: '11' },
		{ label: 'December', value: '12' },
	];
	let chipTxt = ref('');
	let search = ref('');
	let chkInput = ref(false);
	let pageName = ref('');
	let filter = ref([]);
	let filters = ref([]);
	let price = ref('');
	let prices = ref([]);

	/*------------------------------- Setup -----------------------------*/

	mode.value = route.name.split(".")[0];

	// let title = state.device!='home'?state.device.toUpperCase()+" Thailand Price Chart":'Price';
	// let desc = "Check Price of content on "+state.device.toUpperCase()+" Thailand";

	state.title = state.device!='home'?state.device.toUpperCase()+" Thailand Price Chart":'Price';
	state.desc = "Check Price of content on "+state.device.toUpperCase()+" Thailand";
	state.image = "/images/logo_"+state.device+".png"
	
	if(mode.value=='search'){
		pageName.value = 'Search';
	} else if(mode.value=='searchCompany'){
		pageName.value = 'Search';
		if(state.company!=''){
			formData.value = {
				productKind: 'cp:'+state.company,
				order: 'releaseDate desc',
			};
			state.setCompany('');
		}
	} else if(mode.value=='searchParam'){
		mode = 'search';
		if(route.params.txt != ''){
			search.value = route.params.txt;
			searchData();
		}
		pageName.value = 'Search';
	} else if(mode.value=='library'){
		if(state.user.uid=='') window.location.href = '/'+state.device;
		pageName.value = 'My Library '+loc.lib;
		formData.value = {
			productKind: 'library',
			order: 'releaseDate desc',
		};
	} else if(mode.value.startsWith('deal')){
		pageName.value = 'Deal';
		formData.value = {
			search:'',
			price: 'deal',
			productKind: 'all',
			order: 'cdate desc',
			limit: '1200',
		};
	} else if(mode.value=='recent'){
		pageName.value = 'Recently Add';
		formData.value = {
			productKind: 'all',
			order: 'ddate desc',
			limit: '200',
		};
	} else if(mode.value=='preorder'){
		pageName.value = 'Pre-Order';
		formData.value = {
			productKind: 'po',
			order: 'releaseDate',
			// limit: '',
		};
	} else if(mode.value=='newrelease'){
		pageName.value = 'New Release';
		formData.value = {
			productKind: 'new',
			order: 'releaseDate desc',
			limit: '200',
		};
	} else if(mode.value=='plus'){
		pageName.value = 'Playstation Plus';
		formData.value = {
			productKind: 'plus',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='essentials'){
		pageName.value = 'Playstation Plus Essentials';
		formData.value = {
			productKind: 'ess',
			order: 'ddate desc',
		};
	} else if(mode.value=='extra'){
		pageName.value = 'Playstation Plus Extra';
		formData.value = {
			productKind: 'extra',
			order: 'ddate desc',
		};
	} else if(mode.value=='deluxe'){
		pageName.value = 'Playstation Plus Deluxe';
		formData.value = {
			productKind: 'deluxe',
			order: 'ddate desc',
		};
	} else if(mode.value=='gamepass'){
		pageName.value = 'Game Pass Save up to 20% on these games now';
		formData.value = {
			productKind: 'gp',
			order: 'ddate desc',
		};
	} else if(mode.value=='subscription'){
		pageName.value = 'Subscription';
		formData.value = {
			productKind: 'subs',
			order: 'ddate desc',
		};
	} else if(mode.value=='subthai'){
		pageName.value = 'Thai Subtitle'
		formData.value = {
			productKind: 'sthai',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='modthai'){
		pageName.value = 'Mod Thai'
		formData.value = {
			productKind: 'modthai',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='bundle'){
		pageName.value = 'Bundle'
		formData.value = {
			productKind: 'bundle',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='lowest'){
		pageName.value = 'Lowest Price'
		formData.value = {
			price: 'lowest',
			// productKind: 'all',
			order: 'cdate desc',
		};
	} else if(mode.value=='calendar'){
		pageName.value = 'Calendar';
		const d = new Date();
		year.value = d.getFullYear();
		for(let i=2020;i<=year.value+1;i++){
			years.value.push(i);
		}
		month.value = months[d.getMonth()];
		refreshCd();
	} else if(mode.value=='voucher1'){
		pageName.value = 'Voucher US';
		formData.value = {
			productKind: 'zone1',
			order: 'releaseDate desc',
		};
	}
	else if(mode.value=='voucher3'){
		pageName.value = 'Voucher JP';
		formData.value = {
			productKind: 'zone3',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='studio'){
		pageName.value = route.params.txt;
		formData.value = {
			productKind: 'studio:'+route.params.txt,
			order: 'releaseDate desc',
		};
	} else if(mode.value=='pc'){
		pageName.value = 'Play on PC'
		formData.value = {
			filter: 'PC',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='leaving'){
		pageName.value = 'Leaving Soon Save up to 20% on these games now'
		formData.value = {
			productKind: 'leaving',
			order: 'releaseDate desc',
		};
	} else if(mode.value=='showLib'){
		pageName.value = 'Share Library'
		formData.value = {
			link: route.query.lib,
			productKind: 'showLib',
			order: 'releaseDate desc',
		};
	}

	// state.updateTitle(title, desc);

	formData.value.lib = loc.lib;
	formData.value.store = loc.store;
	formData.value.currency = loc.currency;
	formData.value.showARS = loc.showARS;

	if(state.device == 'playstation'){
		filters.value = [
			{ label: 'PS5', value: 'ps5' },
			{ label: 'PS4', value: 'ps4' },
			{ label: 'PSVR', value: 'vr' },
			{ label: 'Standard', value: 'standard' },
			{ label: 'Bundle', value: 'bundle' },
			{ label: 'Essentials', value: 'ess' },
			{ label: 'Extra', value: 'extra' },
			{ label: 'Deluxe', value: 'deluxe' },
			{ label: 'Thai Subtitle', value: 'sthai' },
		];
		prices.value = [
			{ label: 'All', value: 'all' },
			{ label: 'Deal All', value: 'deal' },
			{ label: 'Deal With Plus', value: 'plus' },
			{ label: 'Deal Normal', value: 'normal' },
			{ label: '0-499', value: '0-499' },
			{ label: '500-999', value: '500-999' },
			{ label: '1000-1499', value: '1000-1499' },
			{ label: '1500-1999', value: '1500-1999' },
			{ label: '2000-2499', value: '2000-2499' },
			{ label: '> 2500', value: '2500' },
		]
	} else if(state.device == 'xbox'){
		filters.value = [
			{ label: 'For X|S', value: 'XS' },
			{ label: 'Play Anywhere', value: 'XPA' },
			{ label: 'Playable on PC', value: 'PC' },
			{ label: 'Cloud Save', value: 'Cloud' },
			{ label: '4K', value: '4k' },
			{ label: 'HDR', value: 'HDR' },
			{ label: 'RayTracing', value: 'Ray' },
			{ label: 'VRR', value: 'VRR' },
			{ label: '60 fps+', value: '60' },
			{ label: '120 fps', value: '120' },
			{ label: 'Dolby Atmos', value: 'Atmos' },
			{ label: 'DTSX', value: 'DTSX' },
			{ label: 'Spatial Sound', value: 'Spatial' },
			{ label: 'Single Player', value: 'Single' },
			{ label: 'Local co-op', value: 'LocalCoop' },
			{ label: 'Online co-op', value: 'OnlineCoop' },
			{ label: 'Cross co-op', value: 'formCoop' },
			{ label: 'Local Multi', value: 'LocalMulti' },
			{ label: 'Online Multi', value: 'OnlineMulti' },
			{ label: 'Cross Multi', value: 'formMulti' },
			{ label: 'Mouse&Keyboard', value: 'Mouse' },
		]
		prices.value = [
			{ label: 'All', value: 'all' },
			{ label: 'Deal All', value: 'deal' },
			{ label: 'Deal With Gold', value: 'gold' },
			{ label: 'Deal Normal', value: 'normal' },
			{ label: '>= 50%', value: 'more' },
			{ label: '< 50%', value: 'less' },
			{ label: 'Pug', value: 'pug' },
		]
	} else if(state.device == 'nintendo'){
		filters.value = [
			{ label: 'US', value: '1' },
			{ label: 'EU', value: '2' },
			{ label: 'JP', value: '3' },
			{ label: 'HK', value: '4' },
			{ label: 'Action', value: 'Action' },
			{ label: 'Party', value: 'Party' },
			{ label: 'Platformer', value: 'Platformer' },
			{ label: 'RPG', value: 'RPG' },
			{ label: 'Simulation', value: 'Simulation' },
			{ label: 'Sport', value: 'Sport' },
			{ label: 'Fighting', value: 'Fighting' },
			{ label: 'Puzzle', value: 'Puzzle' },
			{ label: 'Racing', value: 'Racing' },
			{ label: 'Arcade', value: 'Arcade' },
			{ label: 'Music', value: 'Music' },
			{ label: 'Shooter', value: 'Shooter' },
			{ label: 'Board', value: 'Board' },
		]
		prices.value = [
			{ label: 'All', value: 'all' },
			{ label: 'Deal', value: 'deal' },
			{ label: 'Pug', value: 'pug' },
		]
	}else if(state.device == 'steam'){
		filters.value = [
			{ label: 'Game', value: 'game' },
			{ label: 'DLC', value: 'dlc' },
			{ label: 'Thai Subtitle', value: 'thai' },
		];
		prices.value = [
			{ label: 'All', value: 'all' },
			{ label: 'Deal', value: 'deal' },
			{ label: '0-499', value: '0-499' },
			{ label: '500-999', value: '500-999' },
			{ label: '1000-1499', value: '1000-1499' },
			{ label: '1500-1999', value: '1500-1999' },
			{ label: '2000-2499', value: '2000-2499' },
			{ label: '> 2500', value: '2500' },
		]
	} else if(state.device == 'appletv'){
		filters.value = [
			{ label: 'Movie', value: 'movie' },
			{ label: 'Bundle', value: 'bundle' },
			{ label: "Animation", value: "Animation" },
			{ label: "Sci-Fi", value: "Sci-Fi" },
			{ label: "Drama", value: "Drama" },
			{ label: "Adventure", value: "Adventure" },
			{ label: "Action", value: "Action" },
			{ label: "Thriller", value: "Thriller" },
			{ label: "Horror", value: "Horror" },
			{ label: "Comedy", value: "Comedy" },
			{ label: "Musical", value: "Musical" },
			{ label: "Crime", value: "Crime" },
			{ label: "Fantasy", value: "Fantasy" },
			{ label: "Classics", value: "Classics" },
			{ label: "Biography", value: "Biography" },
			{ label: "Kids & Family", value: "Kids & Family" },
			{ label: "Music", value: "Music" },
			{ label: "Romance", value: "Romance" },
			{ label: "Mystery", value: "Mystery" },
			{ label: "Anime", value: "Anime" },
			{ label: "Documentary", value: "Documentary" },
			{ label: "History", value: "History" },
			{ label: "Short Films", value: "Short Films" },
			{ label: "Western", value: "Western" },
			{ label: "Holiday", value: "Holiday" },
		]
		prices.value = [
			{ label: "All", value: "all" },
			{ label: "Deal", value: "deal" },
			{ label: "Lowest Price", value: "lowest" },
			{ label: "0-99", value: "0-99" },
			{ label: "100-149", value: "100-149" },
			{ label: "150-199", value: "150-199" },
			{ label: "200-249", value: "200-249" },
			{ label: "250-349", value: "250-349" },
			{ label: "350-449", value: "350-449" },
			{ label: "450-599", value: "450-599" },
			{ label: ">=600", value: "600-9999" },
		]
	}

	function refreshCd(){
		formData.value = {
			productKind: 'cd:'+year.value+month.value.value,
			order: 'releaseDate',
			store: loc.store,
			currency: loc.currency,
			lib: loc.lib,
		};
		refresh.value++;
		chipTxt.value = month.value.label+" "+year.value;
	}

	function searchData(){
		chkInput.value = false;
		if(!search.value) search.value = '';
		if(!filter.value) filter.value = [];
		if(!price.value) price.value = '';
		if(search.value=='' && filter.value.length==0 && price.value==''){
			chkInput.value = true;
			return;
		}
		formData.value = {
			search: search.value,
			filter: filter.value.join("|"),
			price: price.value,
			productKind: 'all',
			order: 'releaseDate desc',
			store: loc.store,
			currency: loc.currency,
			limit: '0,1200',
			lib: loc.lib,
		};
		refresh.value++;
	}

</script>