<template>
    <q-card id="detail" style="width:700px;max-width: 90vw;" class="row q-py-md q-px-sm">
        <div data-html2canvas-ignore="true" style="position: absolute;right:10px">
            <div><q-btn flat v-close-popup round dense icon="close" /></div>
            <div v-if="device=='playstation'"><q-btn flat round dense icon="mdi-sony-playstation" @click="open('https://store.playstation.com/'+storeURL+'/product/'+item.sid)"></q-btn></div>
            <div v-else-if="device=='xbox'"><q-btn flat round dense icon="mdi-microsoft-xbox" @click="open(item.url)"></q-btn></div>
            <!-- <q-btn v-else-if="device=='nintendo'" flat round dense icon="mdi-nintendo-switch" @click="openNin(item.nsuid,item.flag)"></q-btn> -->
            <div v-else-if="device=='steam'"><q-btn flat round dense icon="mdi-steam" @click="open(item.url)"></q-btn></div>
            <div v-else-if="device=='appletv' && item.url2"><q-btn flat round dense icon="mdi-apple" @click="open(item.url2)"></q-btn></div>
            <div v-if="device=='appletv'"><q-btn flat round dense icon="mdi-music" @click="open(item.url)"></q-btn></div>
            <div v-if="device=='appletv' && item.status=='E' && (item.rotten || item.rotten!='')"><q-btn flat round dense @click="open(item.rotten)">
                <q-avatar size="sm">
                    <img src="/images/other/tomatometer-2.svg">
                </q-avatar>
            </q-btn></div>
            <q-btn flat round dense icon="mdi-google" 
                @click="open('https://www.google.com/search?q='+item.title.replace(/&/g,' and ')+' '+(device=='appletv'?'trailer':device))"></q-btn><br>
            <q-btn flat round dense icon="mdi-youtube" 
                @click="open('https://www.youtube.com/results?search_query='+item.title.replace(/&/g,' and ')+' '+(device=='appletv'?'trailer':device))"></q-btn><br>
            <q-btn v-if="screenWidth=='small' && !hideContent && device!='appletv'" flat round dense icon="mdi-eye-off-outline" @click="hideContent=true"></q-btn>
            <q-btn v-else-if="screenWidth=='small' && hideContent && device!='appletv'" flat round dense icon="mdi-eye-outline" @click="hideContent=false"></q-btn><br/>
            <q-btn v-if="false" flat round dense icon="photo_camera" @click="copyPic('detail')"></q-btn><br>
            <q-btn v-if="device=='appletv' && state.user.uid==1" flat round dense icon="refresh" @click="update(item.aid,false)"></q-btn><br>
            <q-btn v-if="device=='xbox' && state.user.uid==1" flat round dense icon="refresh" @click="update(item.xid,false)"></q-btn><br>
            <q-btn v-if="device=='xbox' && state.user.uid==1" flat round dense icon="replay_10" @click="update(item.xid,true)"></q-btn><br>
            <q-btn v-if="device=='xbox' && state.user.uid==1" flat round dense icon="paid" @click="open('https://api.numcreator.com/xbox/getallprice?xid='+item.xid)"></q-btn><br>
            <!-- <q-btn v-if="device=='xbox' && state.user.uid==1" flat round dense icon="settings" @click="open('https://api.numcreator.com/xbox/getdetail?xid='+item.xid)"></q-btn><br> -->
        </div>
        <div class="q-pl-md col-xs-10 col-sm-4 col-md-4">
            <q-card v-if="device=='playstation'" class="my-card">
                <div style="position: absolute;right:6px">
                    <span style="color:#EEB400" v-if="item.essentials==1"><b>Essentials</b></span>
                    <span style="color:#EEB400" v-if="item.plus"><b>{{item.plus}}</b></span>
                </div>
                <div v-if="item.platform.indexOf('PS4')>=0" style="background-color:#2E86C1">
                    <q-img
                        src="/images/ps4.png"
                        class="q-ma-xs"
                        width="40%"
                    >
                    </q-img>
                </div>
                <div v-else style="background-color:white" >
                    <q-img
                        src="/images/ps5.png"
                        class="q-ma-xs"
                        width="40%"
                    >
                    </q-img>
                </div>
                <q-img
                    :src="posterURL(item)"
                    loading="lazy"
                    spinner-color="white"
                >
                </q-img>
            </q-card>

            <q-card v-else-if="device=='xbox'" class="my-card">
                <q-img 
                    :src="'https://image.numcreator.com/xbox/'+item.xid+'.png'"
                    loading="lazy"
                    spinner-color="white"
                    class="cursor-pointer q-hoverable"
                    @click="detail(item)"
                >
                    <q-img v-if="item.xs==1 || item.available=='XboxSeriesX'"
                        src="/images/xs.png"
                        loading="lazy"
                        class="q-ma-none absolute-bottom-left"
                        fit="fill"
                        width="60px"
                        height="10px"
                        style="opacity:0.9"
                    />
                    <span v-if="item.thai!=null" class="q-pl-sm absolute-bottom text-center" style="font-size:14px;color:black;opacity:0.6">
                        <q-chip dense><b>TH</b></q-chip>
                    </span>
                    <q-img v-if="item.gp==1"
                        src="/images/gp.svg"
                        loading="lazy"
                        class="q-ma-none absolute-bottom-right"
                        fit="fill"
                        width="50px"
                        height="10px"
                    />
                    <span v-if="item.available && item.available.indexOf('PC')>=0" class="absolute-top-right" style="color:black;opacity:0.9">
                        <q-chip dense><q-icon dense name="mdi-desktop-tower-monitor" size="xs" /></q-chip>
                    </span>
                </q-img>
            </q-card>

            <q-card v-else-if="device=='nintendo'" class="my-card">
                <q-img 
                    :src="'https://image.numcreator.com/nintendo/'+item.nid+'.png'"
                    loading="lazy"
                    spinner-color="white"
                    class="cursor-pointer q-hoverable"
                    @click="detail(item)"
                >
                    <span v-if="item.zone" class="absolute-top-left">
                        <q-chip color="red" text-color="white" icon="mdi-ticket-percent-outline" size="sm"
                            :label="item.zone==1?'ALL':item.zone==3?'JP':'US'" />
                    </span>
                </q-img>
            </q-card>

            <q-card v-else-if="device=='steam'" class="my-card">
                <q-img
                    :src="'https://image.numcreator.com/steam/'+item.stid+'.png'"
                    loading="lazy"
                    spinner-color="white"
                >
                </q-img>
            </q-card>

            <q-card v-else-if="device=='appletv'" class="my-card">
                <q-img 
                    :src="item.status=='E'?'https://image.numcreator.com/apple/'+item.aid+'.png':item.artwork"
                    loading="lazy"
                    spinner-color="white"
                    class="cursor-pointer q-hoverable"
                    @click="detail(item)"
                >
                    <q-chip v-if="item.kind=='movie'" size="md" class="q-ma-none absolute-bottom-left" dense style="opacity:0.9">
                        <q-avatar>
                            <img :src="'/images/other/tomatometer-'+(item.scoreRT>80?'3':item.scoreRT>60?'2':item.scoreRT>0?'1':'0')+'.svg'">
                        </q-avatar>
                        <b v-if="item.scoreRT!=0">{{ item.scoreRT }}</b>
                    </q-chip>
                    <q-chip v-if="item.kind=='movie' && item.scoreUS!=0" size="md" class="q-ma-none absolute-bottom-right" dense style="opacity:0.9">
                        <q-avatar>
                            <img src="/images/other/tomatometer-us.svg">
                        </q-avatar>
                        <b>{{ item.scoreUS }}</b>
                    </q-chip>
                    <span v-if="item.kind=='movie' && item.audiothai!=0" class="absolute-top-left" style="color:black;opacity:0.9">
                        <q-chip class="q-pa-none" square dense><q-icon dense name="volume_up" size="xs" /></q-chip>
                    </span>
                    <span v-if="item.kind=='movie' && item.subthai!=0" class="absolute-top-right" style="color:black;opacity:0.9">
                        <q-chip class="q-pa-none" square dense><q-icon dense name="mdi-closed-caption-outline" size="sm" /></q-chip>
                    </span>
                </q-img>
            </q-card>

            <div class="q-pt-md" v-if="screenWidth != 'small' && device!='appletv'">
                <q-select
                    v-if="device=='xbox'"
                    :color="state.color"
                    class="q-pb-sm"
                    v-model="ctGrp"
                    :options="ctGrps"
                    label="Country"
                    dense
                    @update:model-value="changeCT()"
                >
                </q-select>
                <div style="margin:0 -40px;" id="graphdiv"></div>
            </div>
        </div>

        <div class="q-px-md col-sm-8 col-md-8">
        
            <div v-if="device=='playstation'">
                <span class="text-h6" @click="copy(item.id)"><b>{{item.title}}</b></span><br>
                <div class="text-subtitle2">{{item.detail}}</div>
                <div data-html2canvas-ignore="true" v-if="!hideContent" >
                    <b>Platform :</b> {{item.platform}} <br>
                    <b>Provider :</b> {{item.provider}} 
                    <q-btn v-if="item.provider != ''" dense round size="sm" icon="search" :color="state.color"
                        @click="state.setCompany('p:'+item.provider);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}"></q-btn> <br>
                </div>
                <b>Release date :</b> {{item.releaseDate}} <br>
                <span v-if="item.sthai == 1"><b>Sub Thai :</b> Yes <br></span>
                <span v-if="item.vthai == 1"><b>Voice Thai :</b> Yes <br></span>
                <b>Lowest Price :</b> {{item.lowPrice}} {{getUnit2}}<br>
            </div>

            <div v-else-if="device=='xbox'">
                <span class="text-h6" @click="copy(item.xid)"><b>{{item.title}}</b></span><br>
                <div data-html2canvas-ignore="true" v-if="!hideContent" >
                    <span v-if="item.available && item.available.indexOf('PC')>=0">
                        <q-chip color="grey-4" text-color="black" dense><q-icon dense name="mdi-desktop-tower-monitor" size="xs" /></q-chip>
                    </span>
                    <template v-for="item in properties" :key="item">
                        <q-chip color="grey-4" text-color="black" dense>{{item}}</q-chip>
                    </template>
                    <br/>
                    <b>Developed by :</b> {{item.developerName}} 
                    <q-btn v-if="item.developerName!=''" dense round size="sm" icon="search" :color="state.color"
                        @click="state.setCompany('d:'+item.developerName);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}"></q-btn> <br>
                    <b>Published by :</b> {{item.publisherName}} 
                    <q-btn v-if="item.publisherName!=''" dense round size="sm" icon="search" :color="state.color"
                        @click="state.setCompany('p:'+item.publisherName);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}"></q-btn> <br>
                    <b>Release date :</b> {{item.releaseDate}} <br>
                    <b>Genre :</b> {{item.category}} <br>
                    <b>Size :</b> {{item.maxInstallSize}} GB<br>
                    <span v-if="item.thai"><b>Sub Thai :</b> {{item.thai}} <br></span>
                </div>
            </div>

            <div v-else-if="device=='nintendo'">
                <span class="text-h6" @click="copy(item.nid)"><b>{{item.title}}</b></span><br>
                <div data-html2canvas-ignore="true" v-if="!hideContent" >
                    <b>Developed by :</b> {{item.developerName}}
                    <q-btn v-if="item.developerName!='-'" dense round size="sm" icon="search" :color="state.color"
                        @click="state.setCompany('d:'+item.developerName);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}"></q-btn> <br>
                    <b>Published by :</b> {{item.publisherName}}
                    <q-btn v-if="item.publisherName!='-'" dense round size="sm" icon="search" :color="state.color"
                        @click="state.setCompany('p:'+item.publisherName);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}"></q-btn> <br>
                    <b>Release date :</b> {{item.releaseDate}} <br>
                    <b>Genre :</b> {{item.category}} <br>
                </div>
            </div>

            <div v-else-if="device=='steam'">
                <span class="text-h6" @click="copy(item.stid)"><b>{{item.title}}</b></span><br>
                <div data-html2canvas-ignore="true" v-if="!hideContent" >
                    <b>Developed by : </b>
                    <router-link v-if="item.developerName!='-'" @click="state.setCompany('d:'+item.developerName);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}">{{item.developerName}}</router-link>
                    <span v-else>-</span>
                    <br>
                    <b>Published by : </b>
                    <router-link v-if="item.publisherName!='-'" @click="state.setCompany('p:'+item.publisherName);" :to="{name:'searchCompany.'+device,params:{txt:Math.random()}}">{{item.publisherName}}</router-link> 
                    <span v-else>-</span>
                    <br>
                    <b>Release date :</b> {{item.releaseDate}} <br>
                    <b>Genre :</b> {{item.category}} <br>
                    <span v-if="item.thai == 1"><b>Sub Thai :</b> Yes <br></span>
                    <span v-if="item.mod != null"><b>Mod Thai :</b> <a :href="item.mod.split('|')[1]" target="_blank">{{ item.mod.split('|')[0] }} </a><br></span>
                </div>
            </div>

            <div v-else-if="device=='appletv'">
                <span class="text-h6" @click="copy(item.aid)"><b>{{item.name}}</b></span><br>
                <div data-html2canvas-ignore="true" >
                    <div v-if="item.kind=='movie'">
                        <img v-if="item.has4K == 1" src="/images/other/4k.svg" height="14" class="q-pr-xs"/>
                        <img v-else src="/images/other/hd.svg" height="14" class="q-pr-xs"/>
                        <img v-if="item.hasHDR == 1" src="/images/other/hdr.svg" height="14" class="q-pr-xs"/>
                        <img v-if="item.hasDolbyVision == 1" src="/images/other/vision.svg" height="14" class="q-pr-xs"/>
                        <img v-if="item.hasDolbyAtmos == 1" src="/images/other/atmos.svg" height="14" class="q-pr-xs"/>
                        <br/>
                        <b>Genre :</b> {{item.genreNames}}
                        <div v-if="item.studio">
                        <b>Studio : </b>
                            <span v-if="item.studio || item.studio!=''">
                                <span v-for="(st,index) in (item.studio).split(';')" :key="index">
                                    <router-link :to="{name:'studio.'+device,params:{txt:st.trim()}}">{{st.trim()}}</router-link>&nbsp;
                                </span>
                            </span>
                            <span v-else>-</span>
                        </div>
                        <div v-if="item.duration"><b>Duration :</b> {{item.duration}} Minute<br></div>
                    </div>
                    <b>Release Date :</b> {{item.releaseDate}}
                    <div v-if="item.status=='E'">
                        <b>{{ item.preorder==1?'Preorder':'Price' }} :</b> <b>฿{{item.price_now}}</b>
                    </div>
                    <div v-else><span color="red">REMOVE</span></div>
                    <div class="q-pb-sm"><i>Last Update : {{item.cdate}}</i></div>
                </div>
            </div>

            <div v-if="device=='playstation'" class="q-pt-md">
                <div class="q-pb-sm"><i>Last Update : {{item.cdate}}</i></div>
                <table :width="tablePrice">
                    <tr><td>Price <span v-if="inter" style="font-size:12px"> ~ {{item.listPrice}} {{getUnit2}}</span></td><td align="right"><b>{{item.listPrice_t}}</b> {{getUnit}}</td></tr>
                    <tr style="color: #02AD14;" v-if="item.disPrice_t != 0">
                        <td>Discount {{item.disPercent}}% <span v-if="inter" style="font-size:12px"> ~ {{item.disPrice}} {{getUnit2}}</span></td>
                        <td align="right"><b>{{item.disPrice_t}}</b> {{getUnit}}</td>
                    </tr>
                    <tr style="color: #D99700;" v-if="item.plusPrice_t != 0">
                        <td>Plus {{item.plusPercent}}% <span v-if="inter" style="font-size:12px"> ~ {{item.plusPrice}} {{getUnit2}}</span></td>
                        <td align="right"><b>{{item.plusPrice_t}}</b> {{getUnit}}</td>
                    </tr>
                    <tr style="color: #0664ff;" v-if="item.eaPrice_t != 0">
                        <td>EA {{item.eaPercent}}% <span v-if="inter" style="font-size:12px"> ~ {{item.eaPrice}} {{getUnit2}}</span></td>
                        <td align="right"><b>{{item.eaPrice_t}}</b> {{getUnit}}</td>
                    </tr>
                </table>
            </div>

            <div v-else-if="device=='xbox'" class="q-pt-md">
                <q-toggle
                    v-if="item.goldPercent != 0"
                    dense
                    v-model="chkGold"
                    color="orange"
                    title="Gold"
                    class="q-pr-sm"
                    label="Gold"
                />
                <q-toggle
                    v-if="item.goldPercent == 0 && item.percent == 0 && item.publisherName == 'Electronic Arts'"
                    v-model="eaplay"
                    color="red"
                    title="EA Play"
                    class="q-pr-sm"
                    label="EA Play"
                ></q-toggle>
                <div><i>Last Update : {{item.cdate}}</i></div>
                <div v-if="item.priceMode!=''" class="q-pb-sm" :style="'color:'+fontColor(item.priceMode)"><i>Deal Ends {{item.dateEnd}} {{diffDate(item.dateEnd)}}</i></div>
                <table :width="tablePrice" cellpadding="0" cellspacing="0">
                        <tr>
                            <td colspan="3" align="right">
                                {{item['pricePercent']>0 ? 'Discount '+item['pricePercent']+'%' : ''}}
                                <q-btn icon="o_info" dense round flat size="sm" @click="showInfoCard=true;"></q-btn>
                                <b>{{ state.cType?'Visa':'Master' }} Card ( {{ state.rate }}% )</b>
                            </td>
                        </tr>
                        <tr v-if="item.lowCT" style="color:red">
                            <td width="25">
                                <img :src="'/images/flag/'+item.lowCT.split('|')[2]+'.svg'" 
                                width="25" fit="fill" class="q-mr-xs cursor-pointer"
                                style="border:1px solid #D5D8DC;"
                                @click="open(item.url.replace(item.url.split('/')[3],item.lowCT.split('|')[0]))"
                                >
                            </td>
                            <td valign="baseline">
                                {{item[item.lowCT.split('|')[2]+"_price"]}} {{item.lowCT.split('|')[1]}} ~ 
                            </td>
                            <td valign="baseline" align="right" style="color:red">
                                <b>{{item['th_'+item.lowCT.split('|')[2]]}}</b> {{getUnit}}
                            </td>
                        </tr>
                    <template v-for="(store,index) in item.sortp" :key="index">
                        <tr v-if="item['th_'+store.ct]" :style="'color:'+(chkGold ? fontColor('m') : item.goldPercent != 0 ? fontColor('') : fontColor(item.priceMode))">
                            <td width="25">
                                <img :src="'/images/flag/'+store.ct+'.svg'" 
                                width="25" fit="fill" class="q-mr-xs cursor-pointer"
                                style="border:1px solid #D5D8DC;"
                                @click="open(item.url.replace(item.url.split('/')[3],store.region))"
                                >
                            </td>
                            <td valign="baseline">
                                {{chkGold ? item[store.ct+"_gold"] : eaplay ? eaPlayCal(item[store.ct+"_price"]) : item[store.ct+"_price"]}} {{store.currency}} ~ 
                            </td>
                            <td valign="baseline" align="right" :style="'color:'+(item.flag==store.ct ? 'red' : '')">
                                <b>{{chkGold ? item['thg_'+store.ct] : eaplay ? eaPlayCal(item['th_'+store.ct]) : item['th_'+store.ct]}}</b> {{getUnit}}
                            </td>
                        </tr>
                    </template>
                </table>
            </div>

            <div v-else-if="device=='nintendo'" class="q-pt-md">
                <div><i>Last Update : {{item.cdate}}</i></div>
                <div v-if="item.priceMode!=''" class="q-pb-sm" :style="'color:'+fontColor(item.priceMode)"><i>Deal Ends {{item.dateEnd}} {{diffDate(item.dateEnd)}}</i></div>
                    <table :width="tablePrice" cellpadding="0" cellspacing="0">
                        <tr>
                            <td :colspan="device=='nintendo'?4:3" align="right">
                                <q-btn icon="o_info" dense round flat size="sm" @click="showInfoCard=true;"></q-btn>
                                <b>{{ state.cType?'Visa':'Master' }} Card ( {{ state.rate }}% )</b>
                            </td>
                        </tr>
                    <template v-for="(store,index) in item.sortp" :key="index">
                        <tr v-if="item[store.ct] && (!showMore || index < 5)">
                            <td width="25">
                                <img :src="'/images/flag/'+store.ct.toLowerCase()+'.svg'" 
                                    width="25" fit="fill" class="q-mr-xs cursor-pointer"
                                    @click="openNin(item['nsuid'+store.rg],store.ct,store.rg,store.link,item['url1'])"
                                    style="border:1px solid #D5D8DC;">
                            </td>
                            <td valign="baseline" width="200">
                                <span style="font-size:12px"> 
                                    {{item[store.ct]}} <span @click="notiCountry(store.ct)" style="cursor: pointer;"><u>{{store.ct}}</u> </span>
                                    ~ {{item['percent'+store.rg]>0 ? item['percent'+store.rg]+'%' : ''}}
                                    <span v-if="store.ct=='AR' && !state.cType"><br>฿{{item['th_AR_novat']}} +Vat {{item['vat']}}</span>
                                    <span v-else-if="store.ct=='AR' && state.cType"><br>no cashback</span>
                                </span>
                            </td>
                            <td v-if="device=='nintendo'">
                                <q-btn v-if="store.ct=='AR'" icon="o_info" dense round flat size="sm" @click="showInfoARS=true;"></q-btn>
                            </td>
                            <td valign="baseline" align="right" :style="'color:'+(index==0 ? 'red':'')">
                                <b>{{item['th_'+store.ct]}}</b>
                                <span v-if="store.ct=='AR'" style="color: orange;"><br><b>{{item['th_AR_2']}}</b></span>
                            </td>
                        </tr>
                        <tr v-if="store.ct=='AR' && (!showMore || index < 5)">
                            <td></td>
                            <td colspan="3">
                                <q-expansion-item
                                    expand-separator
                                    label="More Detail"
                                    header-class="text-red"
                                    dense
                                >
                                    <q-card >
                                        <q-card-section class="q-py-xs">
                                            <span style="color: red;"><b>Tax 74%</b> ( &lt; 2.xxx,xx ARS )</span> <br>
                                            AR Price = {{ (item[store.ct]*1.74).toLocaleString('en-US', {maximumFractionDigits: 2}).replace(".","|").replace(",",".").replace("|",",") }} ARS<br>
                                            THB Price = {{item['th_'+store.ct]}} THB <br>
                                            <div v-if="!state.cType">
                                                <b>MasterCard Only</b> <br>
                                                Real Price = {{item['th_AR_novat']}} THB <br>
                                                Cashback = {{item['vat']}} THB
                                            </div>
                                        </q-card-section>
                                        <q-card-section class="q-pt-xs">
                                            <span style="color: orange;"><b>Tax 53%</b> ( &gt; 2.xxx,xx ARS )</span> <br>
                                            AR Price = {{ (item[store.ct]*1.53).toLocaleString('en-US', {maximumFractionDigits: 2}).replace(".","|").replace(",",".").replace("|",",") }} ARS<br>
                                            THB Price = {{item['th_AR_2']}} THB <br>
                                            <div v-if="!state.cType">
                                                <b>MasterCard Only</b> <br>
                                                Real Price = {{item['th_AR_novat']}} THB <br>
                                                Cashback = {{(item['th_AR_novat'].replace(",","")*0.53).toLocaleString('en-US', {maximumFractionDigits: 2})}} THB
                                            </div>
                                        </q-card-section>
                                    </q-card>
                                </q-expansion-item>
                            </td>
                        </tr>
                    </template>
                </table>
                <q-btn v-if="showMore && item.sortp.length>5" label="Show More" :color="state.color" dense @click="showMore=false"></q-btn>
                
            </div>

            <div v-else-if="device=='steam'" class="q-pt-md">
                <div class="q-pb-sm"><i>Last Update : {{item.cdate}}</i></div>
                <table :width="tablePrice">
                    <tr><td>Price ~</td><td align="right"><b>{{item.listPrice}}</b></td></tr>
                    <tr style="color: #02AD14;" v-if="item.disPercent != 0">
                        <td>Discount {{item.disPercent}}% </td>
                        <td align="right"><b>{{item.disPrice}}</b></td>
                    </tr>
                </table>
            </div>


            <div data-html2canvas-ignore="true" v-if="(screenWidth == 'small')" class="q-mt-md col-xs-12 col-sm-4 col-md-4">
                <q-select
                    v-if="device=='xbox'"
                    :color="state.color"
                    class="q-pb-sm"
                    v-model="ctGrp"
                    :options="ctGrps"
                    label="Country"
                    dense
                    @update:model-value="changeCT()"
                >
                </q-select>
                <div style="margin:0 -60px;" id="graphdiv"></div>
            </div>

            <div data-html2canvas-ignore="true" v-if="device=='appletv'" class="q-mt-md">
                <div style="margin:0 -50px;" id="graphdiv"></div>
            </div>

        </div>
    </q-card>

    <!------------------- Info Card ------------------------>
    <q-dialog v-model="showInfoCard" >
        <q-card class="q-pa-md">
            <div class="q-gutter-md q-pl-md q-pt-md" >
                คุณสามารถเปลี่ยนราคาเป็นเรท VISA หรือ Master Card <br>และใส่ค่าธรรมเนียมธนาคาร ได้ที่ปุ่ม <q-icon class="no-margin" size="sm" name="credit_card" /> ด้านบน
            </div>
        </q-card>
    </q-dialog>

    <!------------------- Info ARS ------------------------>
    <q-dialog v-model="showInfoARS" >
        <q-card class="q-pa-md">
            <b>สิ่งควรรู้ของราคาอาเจนติน่า (AR)</b>
            <div class="q-py-sm">
                อาเจนติน่าจะใช้ . สลับกับ , เช่น 2.290,00 ARS <br>
                และมีการคิดภาษีเพิ่มจากราคาที่แสดงบน Store ดังนั้นเวลาตัดเงินแล้วจะเห็นว่าราคามีการบวกเพิ่มไปจากเดิมอยู่เกือบ 2 เท่า 
                ซึ่งจะมีการคิดภาษีอยู่ 2 เรทคือ 74% และ 53% ตามราคาของเกม
            </div>
            <div class="q-py-sm" style="color: red;">ราคาน้อยกว่า 25xx AR จะใช้ราคาสีแดงหรือดำ (74%)</div>
            <div class="q-py-sm" style="color: orange;">ราคามากกว่า 25xx AR จะใช้ราคาสีส้ม (53%)</div>
            <div class="q-py-sm">แต่ยังไม่ชัวร์ 100% นะครับ ดังนั้นถ้าราคาอยู่ในช่วง 24xx - 26xx ให้คิดเป็นราคาตัวแดงก่อนก็ดีครับ</div>
            <div class="q-py-sm">
                ราคาของอาเจน ณ ตอนนี้จะมีอยู่ 2 เรทคือ Master Card กับ Visa Card ดังนั้นควรสำรวจบัตรของเราให้ดีว่าบัตรของเราที่ใช้คือบัตรอะไร
                และไปกดปุ่มเปลี่ยนบัตร <q-icon class="no-margin" size="sm" name="credit_card" /> ที่ด้านบน ให้ถูกต้องตามบัตรที่เราใช้
                และใส่ค่าธรรมเนียมของธนาคารให้เรียบร้อย เพื่อให้ได้ค่าที่แม่นยำที่สุด
                และสังเกตุให้ดีว่าราคาที่แสดงใน app ธนาคารต่างๆใช่หน่วย THB หรือไม่ จะได้ไม่ต้องตกใจกันครับ
            </div>
            <div class="q-py-sm">
                โดยบัตร Master Card เราจะได้เงินคืนที่เป็นค่า Vat กลับมา ส่วนของ Visa จะไม่ได้ค่า Vat กลับมานะครับ 
                ค่า Vat ที่แสดงในเว็บคือคิดจากค่าภาษี 74% ถ้าเกมไหนคิด 53% จะไม่ได้คืนค่านี้นะครับ<br>
                ส่วนบัตร Visa ถ้าเราจะกดดูเองต้องไปใช้เว็บ Visa ในการแปลงเท่านั้นนะครับ
            </div>
            
        </q-card>
    </q-dialog>

    <!------------------- Capture ------------------------>
    <q-dialog id="capture" v-model="showPic" max-width="600">
        <q-card :bordered="true" style="width:100%">
            <q-img :src="picSave" width="100%"></q-img>
            <q-separator />
            <q-card-section>
                You can copy this picture by right click or hold press on mobile and choose copy image
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script setup>

    /*------------------------------- Import -----------------------------*/
    import { ref,getCurrentInstance,toRef,computed} from 'vue';
    import { useState } from '../stores/webStore';
    import axios from 'axios';
    import Dygraph from 'dygraphs';
    import html2canvas from 'html2canvas';
    import { useQuasar } from 'quasar';
    const $q = useQuasar();
    const state = useState();
    const props = defineProps({
        formData: {},
    })
    
    /*------------------------------- Data -----------------------------*/
    let loc = ref(state.loc);
    let item = ref(toRef(props, 'formData').value);
    let device = ref(state.device);
    if(state.device=='home') {
        if(item.value.aid)device.value = 'appletv';
        else if(item.value.sid)device.value = 'playstation';
        else if(item.value.xid)device.value = 'xbox';
        else if(item.value.nid)device.value = 'nintendo';
        else if(item.value.stid)device.value = 'steam';
    } 
    let store = loc.value.store;
    let currency = loc.value.currency;
    let poster = getCurrentInstance().appContext.config.globalProperties.$poster;
    let hostUrl = getCurrentInstance().appContext.config.globalProperties.$hostUrl;
    let inter = getCurrentInstance().appContext.config.globalProperties.$inter;
    let showPic = ref(false);
    let picSave = ref('');
    let showInfoCard = ref(false);
    let showInfoARS = ref(false);
    let types = [
        { text: 'Play Anywhere', val: 'XPA' },
        { text: 'Playable on PC', val: 'PC' },
        { text: 'Cloud Save', val: 'Cloud' },
        { text: '4K', val: '4k' },
        { text: 'HDR', val: 'HDR' },
        { text: 'RayTracing', val: 'Ray' },
        { text: 'VRR', val: 'VRR' },
        { text: '60 fps+', val: '60' },
        { text: '120 fps', val: '120' },
        { text: 'Dolby Atmos', val: 'Atmos' },
        { text: 'DTSX', val: 'DTSX' },
        { text: 'Spatial Sound', val: 'Spatial' },
        { text: 'Single Player', val: 'Single' },
        { text: 'Local co-op', val: 'LocalCoop' },
        { text: 'Online co-op', val: 'OnlineCoop' },
        { text: 'Cross co-op', val: 'formCoop' },
        { text: 'Local Multi', val: 'LocalMulti' },
        { text: 'Online Multi', val: 'OnlineMulti' },
        { text: 'Cross Multi', val: 'formMulti' },
        { text: 'Mouse&Keyboard', val: 'Mouse' },
    ];
    // let stores = ref(['us','ar','tr','in','br','kr','au','sa','th']);
    // let region = ref(['en-US','es-AR','tr-TR','en-IN','pt-BR','ko-KR','en-au','en-sa','th-TH']);
	// let currencyXB = ['USD','ARS','TRY','INR','BRL','KRW','AUD','SAR','THB'];
    let chkGold = ref(false);
    if(item.value.goldPercent != 0) chkGold.value = true;
    let eaplay = ref(false);
    if(item.value.goldPercent == 0 && item.value.percent == 0 && item.value.publisherName == 'Electronic Arts') eaplay.value = true;
    let hideContent = ref(false);
    if(screen.width<450) hideContent.value = true;
    if(device.value == 'playstation' || device.value == 'steam') hideContent.value = false;
    let ctGrp = ref();
    let ctGrps = ref([]);


    if(device.value=='nintendo'){
        let storesNin = ['All','US','AR','CA','CL','CO','MX','PE','AT','AU','BE','BG','CH','CY',
                        'CZ','DE','DK','EE','ES','FI','FR','GB','GR','HR','HU','IE','IT','LT',
                        'LU','LV','MT','NL','NO','NZ','PL','PT','RO','RU','SE','SI','SK','ZA','JP','HK'];

        let sortp = [];
        for(let i=0; i<storesNin.length ; i++){
            let ct = storesNin[i];
            if(item.value['th_'+ct]!=null){
                let rg = 2;
                let link = '';
                if(i<=7) {
                    rg = 1;
                    if(ct=='US' || ct=='CA') link = 'en-'+ct;
                    else link = 'es-'+ct;
                }
                else if(ct=='JP') rg = 3;
                else if(ct=='HK') rg = 4;
                
                sortp.push({'ct':ct, 'rg':rg, 'price':item.value['th_'+ct].replace(',',''), 'link':link})
            }
        }
        sortp.sort((a,b)=>Number(a.price)-Number(b.price));
        item.value.sortp = sortp;
    } else if(device.value=='xbox'){
        let storesXbox = ['us','ar','tr','in','br','kr','au','sa','th'];
        let currency = ['USD','ARS','TRY','INR','BRL','KRW','AUD','SAR','THB'];
        let region = ['en-US','es-AR','tr-TR','en-IN','pt-BR','ko-KR','en-au','ar-sa','th-TH'];
        let sortp = [];
        for(let i=0; i<storesXbox.length ; i++){
            let ct = storesXbox[i];
            let price = 'th_';
            if(item.value.priceMode == 'm') price = 'thg_';
            if(item.value[price+ct]!=null){
                sortp.push({'ct':ct, 'price':item.value[price+ct].replace(',',''), 'currency':currency[i], 'region':region[i]});
                ctGrps.value.push(ct.toUpperCase());
            }
        }
        sortp.sort((a,b)=>Number(a.price)-Number(b.price));
        item.value.sortp = sortp;
        ctGrp.value = item.value.flag.toUpperCase();
    }
    
    let showMore = ref(true);

    /*------------------------------- Computed -----------------------------*/

    const getUnit = computed(() => {
        let val = currency;
        if(val == 'us') return "USD";
        else if(val == 'jp') return "JPY";
        else if(val == 'tr') return "TRY";
        return "THB";
    })

    const getUnit2 = computed(() => {
        let val = store;
        if(val == 'us') return "USD";
        else if(val == 'jp') return "JPY";
        else if(val == 'tr') return "TRY";
        return "THB";
    })

    const widthGraph = computed(() => {
        let x = screen.width;
        if(device.value=='appletv' && x>=750) return '400'
        switch (true) {
            case (x < 450): return '300'
            case (x < 1024): return '200'
            case (x >= 1024): return '200'
        }
        
        return '200'
    })

    const storeURL = computed(() => {
        switch (store) {
            case 'th': return 'en-th'
            case 'us': return 'en-us'
            case 'jp': return 'ja-jp'
            case 'tr': return 'tr-tr'
        }
        return 'en-th'
    })

    const screenWidth = computed(() => {
        let x = screen.width;
        switch (true) {
            case (x < 450): return 'small'
            case (x < 1024): return 'medium'
            case (x >= 1024): return 'large'
        }
        return 'large'
    })

    const tablePrice = computed(() => {
        if(screenWidth.value=='small') return '100%'
        return '70%'
    })

    const properties = computed(() => {
        let result = [];
        if(item.value.properties){
            let obj = item.value.properties;
            obj.split(',').forEach(prop => {
                types.forEach(type => {
                    if(prop.indexOf(type.val)>=0){
                        result.push(type.text);
                    }
                });
            });
        } 
        return result;
    })

    genGraph(item.value);

    /*------------------------------- Method -----------------------------*/

    async function genGraph(obj){
        let form = {
            id:obj.id,
            store:store,
            country: obj.flag,
            device:device.value,
            cType:state.cType?'visa':'master',
            rate:state.rate,
        }
        await axios.post(hostUrl+'api/getPrice', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                let priceChart = response.data.data;
                let option  = {
                    axes: {
                        x: {
                            valueFormatter: function (d) {
                                return (new Date(d).toLocaleString("fr-CA",{year:"numeric",month:"2-digit", day:"2-digit"}));
                            }
                        }
                    },
                    height:150,
                    width:widthGraph.value,
                    // labels:["Date","ราคา"],
                    showRangeSelector:false,
                    fillGraph:true,
                    stepPlot: true,
                    colors: ['green', '#A04000','#2874A6'],
                    interactionModel: {}
                }
                // if(device.value == 'appletv') option.labels = ["Date","Price"];

                if(device.value == 'nintendo') option.legendFormatter = legendFormatter;
                new Dygraph(
                    document.getElementById('graphdiv'),
                    priceChart,option
                    
                );
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function legendFormatter(data) {
        // if (data.x == null) return ''; 

        return data.xHTML + '<br>' +
            data.series
            .map(function(v){
                let val = (v.yHTML==100)?0:100-v.yHTML;
                return "<b><span color='"+v.color+"'>Deal "+v.label+"</span></b> : " + val+'%<br>'
            } ) 
            .join(' ');
    }

    function fontColor(mode){
        if(mode=='d'){
            return '#02AD14';
        } else if(mode=='m'){
            return '#D99700';
        } else {
            return ''
        }
    }

    function posterURL(item){
        if(store == 'th'){
            return poster+'images/poster/'+item.sid+'.png';
        } else {
            return item.poster;
        }
    }

    function open(id){
        window.open(id);
    }

    function openNin(id,flag,rg,link,url1){
        let type = 'titles';
        let url = '';
        if(id.startsWith('7001')) type = 'titles';
        else if(id.startsWith('7007')) type = 'bundles';
        else type = 'aocs';
        // if(flag=='JP') url = 'https://ec.nintendo.com/JP/ja/'+type+'/'+id;
        // else if(flag=='hk') url = 'https://ec.nintendo.com/HK/zh/'+type+'/'+id;
        // else url = 'https://ec.nintendo.com/'+flag+'/en/'+type+'/'+id;

        if(rg==1){
            var sub = "";
            if(link) sub = "/"+link
            if(url1){
                url = "https://www.nintendo.com"+sub+url1;
            } else {
                url = "https://www.nintendo.com"+sub;
            }
            
        } else if(rg==2){
            url = 'https://ec.nintendo.com/'+flag+'/en/'+type+'/'+id;
        } else if(rg==3){
            url = "https://store-jp.nintendo.com/list/software/"+id+".html";
        } else if(rg==4){
            url = "https://store.nintendo.com.hk/"+id;
        }
        window.open(url);
    }

    function copy(txt){
        navigator.clipboard.writeText(txt)
    }

    async function copyPic(id){
        showPic.value = true;
        html2canvas(document.querySelector("#"+id),{scale:4}).then(canvas => {
            canvas.toBlob(blob => {
                picSave.value = window.URL.createObjectURL(blob, id+'.png');
            });
        });
    }

    function diffDate(d) {
        if(d==null) return "";
        var d1 = new Date(d.replace(" ", "T"));
        var d2 = new Date();
        var t1 = d1.getTime();
        var t2 = d2.getTime();
        if(Math.floor((t1-t2)/(24*3600*1000))>0){
            return "in "+Math.floor((t1-t2)/(24*3600*1000))+" Days";
        } else if(Math.floor((t1-t2)/(3600*1000))>0){
            return "in "+Math.floor((t1-t2)/(3600*1000))+" Hour";
        } else if(Math.floor((t1-t2)/(60*1000))>0){
            return "in "+Math.floor((t1-t2)/(60*1000))+" Min";
        }
        else {
            return "";
        }
    }

    function eaPlayCal(price){
        return parseFloat(parseFloat(price.replace(/,/g,"")*0.9).toFixed(2)).toLocaleString();
    }

    function notiCountry(ct){
        let msg = '';

        switch (ct) {
            case 'US': msg = 'United States (USD)'; break;
            case 'AR': msg = 'Argentina (ARS)'; break;
            case 'BR': msg = 'Brazil (BRL)'; break;
            case 'CA': msg = 'Canada (CAD)'; break;
            case 'CL': msg = 'Chile (CLP)'; break;
            case 'CO': msg = 'Colombia (COP)'; break;
            case 'MX': msg = 'Mexico (MXN)'; break;
            case 'PE': msg = 'Peru (PEN)'; break;
            case 'AT': msg = 'Austria (EUR)'; break;
            case 'AU': msg = 'Australia (AUD)'; break;
            case 'BE': msg = 'Belgium (EUR)'; break;
            case 'BG': msg = 'Bulgaria (EUR)'; break;
            case 'CH': msg = 'Switzerland (CHF)'; break;
            case 'CY': msg = 'Cyprus (EUR)'; break;
            case 'CZ': msg = 'Czech Republic (CZK)'; break;
            case 'DE': msg = 'Germany (EUR)'; break;
            case 'DK': msg = 'Denmark (DKK)'; break;
            case 'EE': msg = 'Estonia (EUR)'; break;
            case 'ES': msg = 'Spain (EUR)'; break;
            case 'FI': msg = 'Finland (EUR)'; break;
            case 'FR': msg = 'France (EUR)'; break;
            case 'GB': msg = 'United Kingdom (GBP)'; break;
            case 'GR': msg = 'Greece (EUR)'; break;
            case 'HR': msg = 'Croatia (EUR)'; break;
            case 'HU': msg = 'Hungary (EUR)'; break;
            case 'IE': msg = 'Ireland (EUR)'; break;
            case 'IT': msg = 'Italy (EUR)'; break;
            case 'LT': msg = 'Lithuania (EUR)'; break;
            case 'LU': msg = 'Luxembourg (EUR)'; break;
            case 'LV': msg = 'Latvia (EUR)'; break;
            case 'MT': msg = 'Malta (EUR)'; break;
            case 'NL': msg = 'Netherlands (EUR)'; break;
            case 'NO': msg = 'Norway (NOK)'; break;
            case 'NZ': msg = 'New Zealand (NZD)'; break;
            case 'PL': msg = 'Poland (PLN)'; break;
            case 'PT': msg = 'Portugal (EUR)'; break;
            case 'RO': msg = 'Romania (EUR)'; break;
            case 'RU': msg = 'Russian Federation (RUB)'; break;
            case 'SE': msg = 'Sweden (SEK)'; break;
            case 'SI': msg = 'Slovenia (EUR)'; break;
            case 'SK': msg = 'Slovakia (EUR)'; break;
            case 'ZA': msg = 'South Africa (ZAR)'; break;
            case 'JP': msg = 'Japan (JPY)'; break;
            case 'HK': msg = 'Hong Kong (HKD)'; break;
        }

        $q.notify({
            message: msg,
            color: 'red',
            position: 'bottom',
            timeout: 1000
        })
    }

    async function update(id,price){
		let form = {
            'id': id,
            'device': state.device,
            'price': price,
        }
        await axios.post(hostUrl+'api/update', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                console.log(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
	}

    async function changeCT(){
        let tmpJson = {...item.value};
        tmpJson.flag = ctGrp.value;
        await genGraph(tmpJson);
    }

</script>